import React, { useState, Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import axios from "axios";
import Config from "../../../Config";

const AddressSearch = ({ ...props }) => {
  let api_token = props.token;
  let cb = props.parentCallback;
  let delayDebounceFn;
  const [query, setQuery] = useState(props.defaultAddress);
  const [results, setResults] = useState([]);
  const [address, setAddress] = useState({});
  const [addressClick,setAddressClick] = useState(true);

  const confirmAddress = function(payload, searchLat) {
    if(searchLat) {

      console.log(payload);

      const headers = {
        "Content-Type": "multipart/form-data",
        "Authorization": "Bearer " + api_token,
      };

      let data = new FormData();
      // data.append("lat", "6.435275500000001");
      // data.append("lng", "3.4147874");
      // data.append("context", "dropoff");
      // data.append("session", "okay");
      // data.append("q", query);

      if(typeof query == 'undefined' || query == '') {
        setResults([]);
        return;
      }

      axios.post(Config.BASE_URL + "api/v3/location/search/" + payload.location_id, data, {
          headers: headers,
      })
      .then((res) => {
          if (res.data) {
            setAddress(res.data);
            setAddressClick(true);
            setQuery(res.data.address);
            setResults([]);
            clearTimeout(delayDebounceFn);
            cb(res.data);
          }
      })
      .catch(function (error) {
        
      });
    } else {
      console.log(payload);
      setAddress(payload);
      setAddressClick(true);
      setQuery(payload.address);
      setResults([]);
      clearTimeout(delayDebounceFn);
      cb(payload);
    }
  }

  const search = function(e) {
    setQuery(e.target.value);
    if(e.target.value.length == 0) {
      setResults([]);
    }
  }

  window.addEventListener('click', function(e){
    e.stopImmediatePropagation();
    if(e.target.className != 'address-container') {
      setResults([]);
    }
  });

  useEffect(() => {

    if(addressClick) {
      setAddressClick(false);
      return;
    }

    delayDebounceFn = setTimeout(() => {
      const headers = {
        "Content-Type": "multipart/form-data",
        "Authorization": "Bearer " + api_token,
      };

      let data = new FormData();
      data.append("lat", "6.435275500000001");
      data.append("lng", "3.4147874");
      data.append("context", "dropoff");
      data.append("session", "okay");
      data.append("q", query);

      if(typeof query == 'undefined' || query == '') {
        setResults([]);
        return;
      }

      axios.post(Config.BASE_URL + "api/v3/location/search", data, {
          headers: headers,
      })
      .then((res) => {
          if (res.data) {
            setResults(res.data);
          }
      })
      .catch(function (error) {
        
      });

    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [query])

  return (
    <div className="address-container">
      <LoginInput
        placeholder=""
        marginTop={5}
        value={query}
        onChange={search}
        type={"text"}
        autoComplete={"false"}
        onFocus={(event, element) => {
          event.target.setAttribute('type', 'text');
        }}
        onBlur={(event, element) => {}}
        autoCorrect='off'
      />

      {results.length > 0
        ? (
            <ul className="address-box" id="clickbox">

              {results.map(function(d){
                  return (
                    <li key={d.id}
                    onClick={(e) => {

                      if(!d.lat) {
                        confirmAddress(d, true);
                      } else {
                        confirmAddress(d);
                      }

                      // setAddress(d);
                      // setAddressClick(true);
                      // setQuery(d.address);
                      // setResults([]);
                      // clearTimeout(delayDebounceFn);
                      // cb(d);
                    }}

                    >{d.address}</li>
                  )
               })}


            </ul>
          )
        : ''
      }

    </div>
  );
};


const LoginInput = styled.input`
    width: 100%;
    height: 44px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #00c795;
    border-radius: 10px;
    font-size: 14px;
    font-family: "Inter";
    background: #F8F9FB;
    border: 1px solid #BCC1CC;
    border-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    background-size: 60px;
    background-repeat: no-repeat;
    ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : "")};
    ${(props) =>
        props.marginRight ? `margin-right:${props.marginRight}px` : ""};
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
`;

export default AddressSearch;
